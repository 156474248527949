export const TABS = {
  inReview: {
    href: '#in-review',
    text: 'In-review',
  },
  approved: {
    href: '#approved',
    text: 'Approved',
  },
  pushedBack: {
    href: '#pushed-back',
    text: 'Pushed-back',
  },
  rejected: {
    href: '#rejected',
    text: 'Rejected',
  },
  resubmitted: {
    href: '#re-submitted',
    text: 'Re-submitted',
  },
};

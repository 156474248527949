/** @jsxImportSource @emotion/react */
'use client';

import { Fragment, useState } from 'react';

import { css } from '@emotion/react';
import {
  Alert,
  AlertDescription,
  Spinner,
  Tabs,
  Text,
} from '@zapier/design-system';
import { Spacing } from '@zapier/theme';

import { REVIEW_STATUS } from '@/constants';
import { useIntegrationsQuery, useReviewsQuery } from '@/hooks';
import { Integration, Review, ReviewExtended } from '@/types';

import { TABS } from './constants';
import ReviewList from './ReviewList';

const Styles = {
  header: css`
    margin-top: ${Spacing.medium}px;
    margin-bottom: ${Spacing.small}px;
  `,
  list: css`
    margin-top: ${Spacing.medium}px;
  `,
};

type Props = {
  initialStatus?: Review['status'];
};

const defaultProps = {
  initialStatus: REVIEW_STATUS.IN_REVIEW,
};

export default function Dashboard({
  initialStatus = defaultProps.initialStatus,
}: Props) {
  const [status, setStatus] = useState(initialStatus);
  const [isResubmission, setIsResubmission] = useState(false);

  const selectedTab =
    status === REVIEW_STATUS.IN_REVIEW && !isResubmission
      ? TABS.inReview
      : status === REVIEW_STATUS.APPROVED
        ? TABS.approved
        : status === REVIEW_STATUS.PUSHED_BACK
          ? TABS.pushedBack
          : status === REVIEW_STATUS.REJECTED
            ? TABS.rejected
            : status === REVIEW_STATUS.IN_REVIEW && isResubmission
              ? TABS.resubmitted
              : null;

  const {
    data: reviews = [],
    error: errorReviewsQuery,
    isLoading: isLoadingReviews,
  } = useReviewsQuery(
    {
      isResubmission,
      status,
    },
    { staleTime: 6000 },
  );
  const integrationIds = reviews.map((review: Review) => review.integrationId);

  const {
    data: integrations = [],
    error: errorIntegrationsQuery,
    isLoading: isLoadingIntegrations,
  } = useIntegrationsQuery({ integrationIds });
  const integrationsMap = integrations.reduce(
    (accm: { [id: string]: Integration }, integration: Integration) => {
      accm[integration.id] = integration;
      return accm;
    },
    {},
  );

  const reviewsExtended: ReviewExtended[] = reviews.map((review) => ({
    ...review,
    integration: integrationsMap[review.integrationId],
  }));

  const isLoading = isLoadingReviews || isLoadingIntegrations;

  return (
    <Fragment>
      <h2 css={Styles.header}>
        <Text type="pageHeader5">Review Queue</Text>
      </h2>
      <section>
        <Fragment>
          <Tabs
            items={[
              TABS.inReview,
              TABS.approved,
              TABS.pushedBack,
              TABS.rejected,
              TABS.resubmitted,
            ]}
            onChooseItem={(item) => {
              if (!isLoading) {
                const { text } = item;
                if (text === TABS.inReview.text) {
                  setStatus(REVIEW_STATUS.IN_REVIEW);
                  setIsResubmission(false);
                } else if (text === TABS.approved.text) {
                  setStatus(REVIEW_STATUS.APPROVED);
                } else if (text === TABS.pushedBack.text) {
                  setStatus(REVIEW_STATUS.PUSHED_BACK);
                } else if (text === TABS.rejected.text) {
                  setStatus(REVIEW_STATUS.REJECTED);
                } else if (text === TABS.resubmitted.text) {
                  setStatus(REVIEW_STATUS.IN_REVIEW);
                  setIsResubmission(true);
                }
              }
            }}
            selectedItemHref={selectedTab?.href ?? ''}
          />
          <div css={Styles.list}>
            {isLoading ? (
              <Spinner />
            ) : errorReviewsQuery ? (
              <Alert type="error">
                <AlertDescription>
                  There is a problem fetching reviews:{' '}
                  {errorReviewsQuery.message}.
                </AlertDescription>
              </Alert>
            ) : errorIntegrationsQuery ? (
              <Alert type="error">
                <AlertDescription>
                  There is a problem fetching reviews:{' '}
                  {errorIntegrationsQuery.message}.
                </AlertDescription>
              </Alert>
            ) : reviews.length ? (
              <ReviewList reviews={reviewsExtended} />
            ) : (
              <Alert type="info">
                <AlertDescription>
                  No reviews to work on! But if that sounds off, please contact{' '}
                  <strong>#team-dev-platform</strong>.
                </AlertDescription>
              </Alert>
            )}
          </div>
        </Fragment>
      </section>
    </Fragment>
  );
}
